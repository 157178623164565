
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























































































































































































































































































































































































































































$bp: 'l';

.flexible-before-after {
  --slider-progress: 50%;

  &.is-dragging {
    cursor: grabbing;
  }
}

.flexible-before-after-inner {
  position: relative;
}

.flexible-before-after__slider {
  @extend %list-nostyle;

  position: relative;
}

.flexible-before-after__slider__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s;

  &.is-visible {
    position: relative;
    opacity: 1;
  }
}

.flexible-before-after__slider__item-inner {
  @extend %border-radius-small;

  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 3/2;
  box-shadow: $card-shadow;

  .is-portrait & {
    aspect-ratio: 2/3;
  }

  @include mq($bp) {
    .is-portrait & {
      display: flex;
      overflow: visible;
      border-radius: 0;
      box-shadow: none;
      aspect-ratio: unset;
    }
  }
}

// Pictures
.flexible-before-after__slider__item__picture,
[class*='flexible-before-after__slider__item__picture--'] {
  @extend %border-radius-small;
  @include get-all-space;

  display: block;

  & + & {
    clip-path: inset(0% 0% 0% var(--slider-progress));
  }

  .is-dragging & {
    pointer-events: none;
    touch-action: none;
  }

  @include mq($bp) {
    .is-portrait & {
      position: relative;
      flex: 1;
      aspect-ratio: 2/3;
      box-shadow: $card-shadow;
      clip-path: none;

      &:first-child {
        margin-right: $spacing * 2;
      }
    }
  }
}

// Labels
.flexible-before-after__slider__item__label,
[class*='flexible-before-after__slider__item__label--'] {
  @extend %ff-alt;
  @extend %fw-medium;

  position: absolute;
  bottom: $spacing * 1.5;
  left: $spacing * 0.8;
  padding: 0.3rem 0.6rem;
  line-height: 1.2;
  background: $c-white;
  border-radius: 1rem;

  .icon {
    display: none;
    transform: rotate(90deg);
  }

  &[class*='--after'] {
    right: $spacing * 0.8;
    left: unset;

    .icon {
      transform: rotate(-90deg);
    }
  }

  @include mq($bp) {
    bottom: $spacing * 1.5;
    left: $spacing * 1.5;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    &[class*='--after'] {
      right: $spacing * 1.5;
      left: unset;
    }

    .is-portrait & {
      @include center-y;

      z-index: 1;
      right: -$spacing;
      left: unset;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: $spacing * 0.25;
      width: 13rem;
      height: fit-content;
      padding: $spacing * 0.35 $spacing * 0.5;
      font-size: 1.8rem;
      border-radius: 2.5rem 0 0 2.5rem;

      .icon {
        display: block;
      }

      &[class*='--after'] {
        right: unset;
        left: -$spacing;
        justify-content: flex-end;
        border-radius: 0 2.5rem 2.5rem 0;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 26rem;
          height: 100%;
          border-radius: 2.5rem;
          box-shadow: $card-shadow;
        }
      }
    }
  }
}

// Caption
.flexible-before-after__slider__item__caption {
  @extend %ff-alt;
  @extend %fw-bold;

  display: flex;
  align-items: flex-start;
  font-size: 1.6rem;
  line-height: 1.9rem;

  ::v-deep em {
    @extend %ff-default;
    @extend %fw-light;

    font-size: 1.8rem;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .icon {
    position: relative;
    top: -0.1rem;
    flex-shrink: 0;
    color: $c-mustard;
  }
}

// Button
.flexible-before-after__slider__button-outer {
  @include center-x;

  top: 0;
  width: calc(100% - calc(var(--wrapper-padding) * 2));
  height: auto;
  aspect-ratio: 3/2;
  pointer-events: none;
  touch-action: none;

  &.is-portrait {
    aspect-ratio: 2/3;
  }

  &::after {
    @include center-x;

    content: '';
    left: var(--slider-progress);
    display: block;
    width: 0.2rem;
    height: 100%;
    background: $c-light-grey;
  }
}

.flexible-before-after__slider__button {
  @extend %button-nostyle;
  @include center-xy;

  z-index: 1;
  left: var(--slider-progress);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  color: $c-mustard;
  background: $c-white;
  border-radius: 50%;
  box-shadow: $card-shadow;
  cursor: grab;
  pointer-events: all;
  touch-action: all;

  /* stylelint-disable no-descending-specificity */
  .icon {
    transform: rotate(90deg);

    &:not(:first-child) {
      transform: rotate(-90deg);
    }
  }
  /* stylelint-enable no-descending-specificity */

  .is-dragging & {
    cursor: grabbing;
  }

  @include mq($bp) {
    width: 8rem;
    height: 8rem;
  }

  @media screen and (pointer: fine) {
    &:focus-visible {
      outline: 0.5rem solid $c-mustard;
    }
  }
}

// Nav
.flexible-before-after__nav {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    display: block;
    width: calc(100% - calc(var(--wrapper-padding) * 2));
    height: 1px;
    margin: 0 var(--wrapper-padding);
    background: $c-light-grey;
    transform: none;
  }

  &::after {
    top: unset;
    bottom: 0;
  }

  @include mq($bp) {
    max-width: var(--wrapper-max-width);
    margin: auto;
    padding: 0 var(--wrapper-padding);

    &::before,
    &::after {
      content: none;
    }
  }
}

.flexible-before-after__nav-inner {
  @include scrollbars(0, $c-light-grey);

  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 4px;
  }
}

.flexible-before-after__nav__items {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: $spacing;
  min-width: fit-content;
  padding: $spacing var(--wrapper-padding);

  @include mq($bp) {
    padding: $spacing 0;
    border-top: 0.1rem solid $c-light-grey;
    border-bottom: 0.1rem solid $c-light-grey;
  }
}

.flexible-before-after__nav__item {
  @extend %button-nostyle;
  @extend %border-radius-small;

  position: relative;
  overflow: hidden;
  width: 10rem;
  aspect-ratio: 3/2;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);

  &:first-child {
    padding-left: 4rem;
  }

  &[aria-selected='true'] {
    border: 2px solid $c-mustard;
  }

  @include mq($bp) {
    width: 15rem;
    box-shadow: $card-shadow;
  }

  @media screen and (pointer: fine) {
    &:focus-visible {
      outline: 5px solid $c-mustard;
    }
  }
}

.flexible-before-after__nav__item__label {
  @extend %visually-hidden;
}

.flexible-before-after__nav__item__picture {
  @include get-all-space;

  img {
    @include image-fit;
  }
}
