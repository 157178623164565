
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























.author {
  text-align: center;
}

.author__picture {
  position: relative;
  overflow: hidden;
  width: 8rem;
  height: 8rem;
  margin: auto;
  border-radius: 50%;

  img {
    @include image-fit(cover);
  }
}

.author__title {
  @extend %fw-bold;

  color: $c-mustard;
}

.author__subtitle {
  @extend %fw-light;
}
