
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































































.pill,
[class*='pill--'] {
  @extend %button-nostyle;

  display: flex;
  align-items: center;
  width: fit-content;
  padding: $spacing * 0.25;
  color: $c-dark-grey;
  text-align: left;
  text-decoration: none;
  background: $c-white;
  border-radius: 2rem;
  box-shadow: $card-shadow;
  transition: transform 0.2s $ease-softer;

  &[class*='--big'] {
    padding: $spacing * 0.5;
    border-radius: 5.4rem;
  }

  &:is(div) {
    cursor: default;
  }

  &[href]:hover,
  &[href]:focus-visible,
  &:is(button):hover,
  &:is(button):focus-visible {
    transform: scale(1.02);
  }
}

.pill__logo {
  display: flex;
  flex-shrink: 0;
  width: 2.6rem;
  height: 2.6rem;
  margin-right: $spacing * 0.5;

  [class*='pill--'][class*='--big'] & {
    width: 4rem;
    height: 4rem;
    margin-right: $spacing * 0.5;
  }

  @include mq(l) {
    [class*='pill--'][class*='--big'] & {
      width: 8rem;
      height: 8rem;
      margin-right: $spacing;
    }
  }
}

.pill__icon {
  display: flex;
  flex-shrink: 0;
  margin-right: $spacing * 0.5;
  padding: $spacing * 0.25;
  color: $c-white;
  background: $c-mustard;
  border-radius: 50%;

  .icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  [class*='pill--'][class*='--big'] & {
    margin-right: $spacing * 0.5;
    padding: $spacing * 0.5;

    .icon {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  @include mq(l) {
    [class*='pill--'][class*='--big'] & {
      margin-right: $spacing;
      padding: $spacing * 1.1;

      .icon {
        width: 3.6rem;
        height: 3.6rem;
      }
    }
  }
}

.pill__content {
  flex: 1;
  margin-right: $spacing * 0.25;
  font-size: 1.3rem;
  font-style: italic;
  line-height: 1;

  strong {
    @extend %ff-alt;

    display: block;
    color: $c-mustard;
    font-style: normal;
  }

  [class*='pill--'][class*='--big'] & {
    @extend %fw-bold;
    @extend %ff-alt;

    margin-right: $spacing * 0.5;
    font-size: 1.6rem;
    font-style: normal;

    strong {
      margin-bottom: $spacing * 0.2;
    }
  }

  @include mq(l) {
    [class*='pill--'][class*='--big'] & {
      margin-right: $spacing * 0.25;
      font-size: 1.6rem;

      strong {
        font-size: 2rem;
      }
    }
  }
}

.pill__arrow {
  --hover-translate: 2px;

  transition: transform 0.2s $ease-softer;

  [class*='pill--'][class*='--right']:hover &,
  [class*='pill--'][class*='--right']:focus-visible & {
    transform: translateX(var(--hover-translate));
  }

  [class*='pill--'][class*='--bottom']:hover &,
  [class*='pill--'][class*='--bottom']:focus-visible & {
    transform: translateY(var(--hover-translate));
  }

  @include mq(l) {
    [class*='pill--'][class*='--big'] & {
      --hover-translate: 5px;

      margin: 0 $spacing;
    }
  }
}
