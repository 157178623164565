
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































.footer-menu {
  font-family: $ff-alt;
  border-bottom: 0.1rem solid var(--c-border);

  .form-showrooms & {
    border-top: 0.1rem solid var(--c-border);
  }
}

.footer-menu__submenus {
  @extend %list-nostyle;

  @include mq(s) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-gap: $spacing * 2 0;
  }

  @include mq(l) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $spacing * 2.5 0;
  }
}

.footer-menu__submenu {
  & + & {
    margin-top: $spacing;
  }

  @include mq(s) {
    & + & {
      margin-top: 0;
    }
  }
}

.footer-menu__submenu__address {
  & + & {
    margin-top: $spacing;
  }
}

.footer-menu__submenu__label {
  color: $c-medium-grey;
  line-height: 4rem;
}

.footer-menu__submenu__links {
  @extend %list-nostyle;
}

.footer-menu__submenu__link {
  @extend %fw-medium;

  display: inline-block;
  padding: $spacing * 0.325;
  padding-left: 0;
  font-size: 1.3rem;
  font-style: normal;
  text-decoration: none;
  transition: color 0.2s;

  &[href]:hover,
  &[href]:focus-visible {
    color: $c-mustard;
  }

  &.btn {
    margin-top: $spacing * 0.325;
    padding: $spacing * 0.5;
    color: $c-white;
    line-height: 1rem;
    background: $c-mustard;
    border: 1px solid $c-mustard;
    border-radius: 0.4rem;
    transition-property: color, background;

    &[href]:hover,
    &[href]:focus-visible {
      color: $c-mustard;
      background: $c-white;
    }
  }
}

.footer-menu__btn {
  margin-top: $spacing * 0.5;
}
