
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































































.header-cta {
  @extend %border-radius-small;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $spacing * 1.5 var(--cta-padding);
  color: $c-white;
  text-decoration: none;
  transform: translate(0); // for fixed grandchild

  & + & {
    margin-top: $spacing * 1.2;
  }

  &.legacy {
    background: $c-mustard;
    outline: 0.1rem solid $c-mustard;
    outline-offset: -0.1rem;
    transition: 0.3s $ease-softer;
    transition-property: background, color;

    &:hover,
    &:focus-visible {
      color: $c-mustard;
      background: $c-white;
    }
  }

  @include mq($until: m) {
    margin-bottom: $spacing;

    &.legacy {
      display: none;
    }
  }

  @include mq($from: xl, $and: '(min-height: #{$menu-desktop-height})') {
    min-height: 18rem;
  }
}

.header-cta__label {
  @extend %ff-alt;

  position: relative;
  z-index: 1;
  font-size: 2rem;
  line-height: 4rem;

  ::v-deep b {
    @extend %fw-bold;

    display: block;
    font-size: 4.5rem;
    line-height: 1;
  }
}

.header-cta__arrow {
  position: relative;
  z-index: 1;
  width: var(--cta-arrow-width);
  height: auto;
  transition: transform 0.3s $ease-softer;

  .header-cta:hover &,
  .header-cta:focus & {
    transform: translateX(20%);
  }
}

.header-cta__picture {
  @extend %border-radius-small;

  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;

  img {
    @include image-fit(contain);
  }

  &::before {
    @extend %border-radius-small;
    @include get-all-space;

    position: fixed;
    content: '';
    z-index: 1;
    background: rgba($c-black, 15%);
    transition: background 0.2s $ease-softer;
  }

  .header-cta:hover &,
  .header-cta:focus & {
    &::before {
      background: rgba($c-black, 30%);
    }
  }

  .header-cta.cover & {
    @include get-all-space;

    overflow: hidden;
    transform: none;

    img {
      @include image-fit;
    }
  }
}
