
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































::v-deep h1.title {
  max-width: 70%;
}

.showroom-single__pill {
  width: auto;
  margin-top: $spacing;
  transform: none;
}

.picture {
  margin-bottom: $spacing * 2;
}

.wysiwyg,
.showroom-single__ctas {
  @include default-margin;
}

.showroom-single__event {
  @include default-margin;

  border-radius: 0.8rem;
  box-shadow: $card-shadow;

  @include mq(s) {
    display: flex;
  }
}

.showroom-single__event__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 3/2;
  border-radius: 0.8rem 0.8rem 0 0;

  img {
    @include image-fit;
  }

  @include mq(s) {
    width: col(3, 10);
    border-radius: 0.8rem 0 0 0.8rem;
  }
}

.showroom-single__event__content {
  flex: 1;
  padding: $spacing;
  border-radius: 0 0 0.8rem 0.8rem;

  @include mq(l) {
    padding: $spacing * 2;
  }
}

.showroom-single__description {
  @include default-margin(0.5);
}
