
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














































































































































































.masonry,
[class*='masonry--'] {
  --masonry-spacing: 1rem;
  --masonry-sizer: 100%;

  @extend %list-nostyle;

  position: relative;
  margin: calc(var(--masonry-spacing) * -1);

  &::after {
    content: '';
    display: block;
    clear: both;
  }

  @include mq(xs) {
    --masonry-sizer: 50%;
  }

  @include mq(l) {
    --masonry-sizer: calc(100% / 3);
  }

  @include mq(xl) {
    --masonry-spacing: 2.5rem;
  }
}

.masonry__item,
.masonry__sizer {
  width: var(--masonry-sizer);
  padding: var(--masonry-spacing);
  will-change: opacity, transform;

  .is-loading & {
    opacity: 0;
  }
}

// Hide items not in masonry layout yet, eg: items added to layout
.masonry__item:not([style]) {
  opacity: 0;
}

.masonry__loader {
  @include center-x;

  top: 0;

  @include mq(l) {
    .loader {
      --loader-size: 6rem;
    }
  }
}
