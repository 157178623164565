// IMPORT GOOGLE FONTS
@import url('https://fonts.googleapis.com/css?family=Martel:300,700&display=swap');

@font-face {
  font-family: 'SpaceMono';
  src: url('/assets/fonts/SpaceMono-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Swis721BT';
  src: url('/assets/fonts/Swis721BT/Swis721BT-Thin.woff2') format('woff2'),
    url('/assets/fonts/Swis721BT/Swis721BT-Thin.woff') format('woff'),
    url('/assets/fonts/Swis721BT/Swis721BT-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Swis721BT';
  src: url('/assets/fonts/Swis721BT/Swis721BT-Light.woff2') format('woff2'),
    url('/assets/fonts/Swis721BT/Swis721BT-Light.woff') format('woff'),
    url('/assets/fonts/Swis721BT/Swis721BT-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Swis721BT';
  src: url('/assets/fonts/Swis721BT/Swis721BT-Roman.woff2') format('woff2'),
    url('/assets/fonts/Swis721BT/Swis721BT-Roman.woff') format('woff'),
    url('/assets/fonts/Swis721BT/Swis721BT-Roman.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Swis721BT';
  src: url('/assets/fonts/Swis721BT/Swis721BT-Medium.woff2') format('woff2'),
    url('/assets/fonts/Swis721BT/Swis721BT-Medium.woff') format('woff'),
    url('/assets/fonts/Swis721BT/Swis721BT-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Swis721BT';
  src: url('/assets/fonts/Swis721BT/Swis721BT-Bold.woff2') format('woff2'),
    url('/assets/fonts/Swis721BT/Swis721BT-Bold.woff') format('woff'),
    url('/assets/fonts/Swis721BT/Swis721BT-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  font-size: calc(1em * 0.625);
}

body {
  /* sass-lint:disable-block no-vendor-prefixes */
  color: $c-gray-darkest;
  font-family: #{$ff-default};
  font-size: $body-text;
  font-weight: 300;
  line-height: 1.65;

  @include mq(m) {
    font-size: $body-text-m;
  }
}
